import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Loading from "../../Components/Loading";
import Sidebar from "../../Components/Sidebar";
import Stepper from "../../Components/Stepper";
import Wizard from "../../Components/Wizard";
import {
  carregaProcesso,
  selecionaPlano,
  setOrcamento,
  setStatus,
} from "../../redux/cadastroSlice";
import { PASSOS, PLANOS, STATUS } from "../../utils/constants";
import DadosEmpresa from "../DadosEmpresa";
import DadosResponsavel from "../DadosResponsavel";
import Funcionarios from "../Funcionarios";
import Pagamento from "../Pagamento";
import SelecaoPlanos from "../SelecaoPlanos";
import Header from "../Home/Header";

import * as S from "./styles";

function Aceite({ orcamento = false }) {
  // pega query params

  const dispatch = useDispatch();
  const { passo_atual, status } = useSelector((state) => state.cadastro);
  // params
  const { uuid } = useParams();
  const [oito, setOito] = useState(false);
  // se tiver params, deve pegar os dados atuais da empresa

  useEffect(() => {
    init();
  }, []);


  async function init() {
    if (uuid) {
      // pega os dados atuais da empresa
      // pega o processo atual da empresa
      dispatch(carregaProcesso(uuid));
    } else {
      dispatch(setStatus({ status: STATUS.IDLE }));
    }
  }

  if (status === STATUS.LOADING) {
    return (
      <S.Container>
        <Loading />
      </S.Container>
    );
  }

  if (status === STATUS.FAILED) {
    return <S.Container>Ocorreu um erro ao carregar o orçamento</S.Container>;
  }

  return (
    <S.Container>
      <Container fluid={false}>
        <Row>
          <Col md={12}>
            <Header />
          </Col>
          <Col md={12}>
            <Pagamento aceite={true} />
          </Col>
        </Row>
      </Container>
    </S.Container>
  );
}

export default Aceite;

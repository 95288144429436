import React from "react";

import * as S from "./styles";

function HandOver() {
  return (
    <S.Container>
    </S.Container>
  );
}

export default HandOver;

import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "reactstrap";
import API from "../../../Api";
import Loading from "../../../Components/Loading";
import Slider from "../../../Components/Slider";
import * as S from "../../main-styles";

import styled from 'styled-components';

 const Seletor = styled(S.Button)`
    background-color: ${props=>props.selected ? "#c14481" : "#ccc"};
    cursor: pointer;
`;


function Funcionarios() {
  const [precoPacote, setPrecoPacote] = useState(0);
  const [state, setState] = useState({
    plano: {},
    loading: true,
    usuarios: 0,
    step: 0,
    plano_selecionado: "enterprise",
  });

  const { usuarios, loading, plano, step, plano_selecionado } = state;
  const {
    maximo_usuarios,
    minimo_usuarios,
    pacote_usuarios,
    preco_pacote,
    preco_usuario,
  } = plano;

  useEffect(() => {
    if(plano_selecionado)
    pegaPacote();
  }, [plano_selecionado]);

  useEffect(() => {
    updatePrecoUsuario();
  }, [usuarios]);


  function updatePrecoUsuario() {
    console.log("Calcula para ", usuarios);
  }

  async function pegaPacote() {
    let planos = await API.PLANOS.get.planos();
    let plano = planos[plano_selecionado];
    console.log("plano", plano);
    let novousuarios = usuarios || plano.minimo_usuarios;
    if (plano.minimo_usuarios > usuarios) {
      novousuarios = plano.minimo_usuarios;
    }
    if(plano.maximo_usuarios < usuarios){
      novousuarios = plano.maximo_usuarios;
    }
    setState({
      ...state,
      plano,
      loading: false,
      usuarios: novousuarios,
    });
  }
  

  function selectPlano(plano) {
    setState({
      ...state,
      plano_selecionado: plano,
    });
    
  }

  function updateUsuarios(step, usuarios) {
    setState({
      ...state,
      usuarios: usuarios,
      step: step,
    });
  }



  let pacotes_necessarios = Math.ceil(usuarios / pacote_usuarios);

  let total_seats = pacotes_necessarios * pacote_usuarios;

  let valor_usuarios = pacotes_necessarios * preco_pacote;




  return (
    <S.Container>
      {loading ? (
        <S.Form>
          {" "}
          <Loading />
        </S.Form>
      ) : (
        <S.Form>
          <Container style={{ marginTop: 20 }}>
            <Row>
              <Col md={4}>
                <Seletor
                  style={{ width: "100%", marginBottom: 30 }}
                  selected={plano_selecionado === "starter"}
                  onClick={() => selectPlano("starter")}
                >
                  Plano Starter
                </Seletor>
              </Col>
              <Col md={4}>
                <Seletor
                  style={{ width: "100%", marginBottom: 30 }}
                  selected={plano_selecionado === "professional"}
                  onClick={() => selectPlano("professional")}
                >
                  Plano Professional
                </Seletor>
              </Col>
              <Col md={4}>
                <Seletor
                  style={{ width: "100%", marginBottom: 30 }}
                  selected={plano_selecionado === "enterprise"}
                  onClick={() => selectPlano("enterprise")}
                >
                  Plano Enterprise
                </Seletor>
              </Col>
              <Col md={6}>
                <S.Box>
                  <S.Subtitle>Informações</S.Subtitle>
                  <span>
                    <strong>Mínimo:</strong> <strong>{minimo_usuarios}</strong>{" "}
                    seats neste plano
                  </span>
                  <span>
                    <b>Máximo:</b>
                    {maximo_usuarios ? (
                      <>
                        <strong> {maximo_usuarios}</strong> seats
                      </>
                    ) : (
                      <>
                        <strong> Sem limite</strong> de seats contratados neste
                        plano
                      </>
                    )}
                  </span>
                  <span>
                    <strong>Pacote de seats:</strong>{" "}
                    <strong>{pacote_usuarios}</strong> seats
                  </span>
                  <span>
                    <strong>Preço do pacote:</strong>
                    <strong> R${preco_pacote},00</strong> (
                    <strong>R${preco_usuario},00</strong> / seat)
                  </span>
                  <span>1 seat = 1 vaga de usuário</span>
                </S.Box>
              </Col>

              <Col md={6}>
                <S.Box>
                  <S.Subtitle>SubTotal</S.Subtitle>
                  <span>
                    <strong>Mensalidade:</strong> R${plano.mensalidade},00
                  </span>
                  <span>
                    <strong>
                      Usuários: {usuarios} de {total_seats} seats
                    </strong>
                    <br />
                    <strong>{pacotes_necessarios}</strong> pacote
                    {pacotes_necessarios != 1 && "s"} de{" "}
                    <strong>{pacote_usuarios}</strong> seats x R$
                    {preco_pacote},00
                  </span>
                  <S.HR />
                  <span>
                    <strong>Total:</strong> R$
                    {plano.mensalidade + valor_usuarios}
                    ,00
                  </span>
                </S.Box>
              </Col>

              <Col md={12}>
                <S.HR />
              </Col>
              <Col md={3}>
                <S.Subtitle>Quantos usuários?</S.Subtitle>
                <S.Input
                  type="number"
                  min={minimo_usuarios}
                  max={maximo_usuarios}
                  value={usuarios}
                  onChange={(e) =>
                    updateUsuarios(pacotes_necessarios - 1, e.target.value)
                  }
                />
              </Col>
              <Col md={9}>
                <Slider
                  min={minimo_usuarios}
                  max={maximo_usuarios}
                  step={pacote_usuarios}
                  value={step}
                  usuarios={usuarios}
                  onChange={updateUsuarios}
                />
              </Col>
            </Row>
          </Container>
        </S.Form>
      )}
    </S.Container>
  );
}

export default Funcionarios;

import React from "react";
import { Col, Container, Row } from "reactstrap";
import {
  Button,
  MainContainer,
  Form,
  Subtitle,
  Text,
  Title,
} from "../main-styles";

import LogoSeidecor from "../../assets/logopblack.png";

import * as S from "./styles";

function Erro() {
  return (
    <MainContainer>
      <Container>
        <Row>
          <Col md={12} style={{paddingTop: 50}}>
            <Form>
              <img src={LogoSeidecor} alt="Logo Seidecor" style={{marginBottom: 30}}  />
              <Title>Ocorreu um erro ao processar seu pedido</Title>
              <Text>
                Entre em contato com o suporte enviando um email para <a href="mailto:suporte@seidecor.com.br">suporte@seidecor.com.br</a>
              </Text>
            </Form>
          </Col>
        </Row>
      </Container>
    </MainContainer>
  );
}

export default Erro

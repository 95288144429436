import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../Api";
import { isDev, PASSOS, STATUS } from "../utils/constants";
import { CADASTRO } from "./types";

// const fetchUserById = createAsyncThunk(
//   CADASTRO.SET_FIELD,
//   async (userId, thunkAPI) => {
//     const response = await userAPI.fetchById(userId);
//     return response.data;
//   }
// );

export const carregaProcesso = createAsyncThunk(
  CADASTRO.LOAD_PROCESS,
  async (uuid, { getState }) => {
    try {
      const state = getState();
      const response = await API.PROCESSO.get.byUUID(uuid);
      console.log("state", state);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const criaProcesso = createAsyncThunk(
  CADASTRO.CREATE_PROCESS,
  async (_, { getState }) => {
    try {
      const { cadastro } = getState();
      const response = await API.PROCESSO.set.dados_empresa(
        cadastro.empresa.nome_fantasia,
        cadastro.empresa.razao_social,
        cadastro.empresa.cnpj,
        cadastro.empresa.email,
        cadastro.empresa.telefone,
        cadastro.empresa.nome_responsavel,
        cadastro.uuid
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const salvaPlano = createAsyncThunk(
  CADASTRO.SALVA_PLANO,
  async (_, { getState }) => {
    try {
      const { cadastro } = getState();
      const response = await API.PROCESSO.set.salva_plano(
        cadastro.uuid,
        cadastro.plano_selecionado
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const setNumeroUsuarios = createAsyncThunk(
  CADASTRO.SET_NUMERO_USUARIOS,
  async (args, { getState }) => {
    try {
      const { usuarios, pacotes, seats, valor_usuarios } = args;
      const { cadastro } = getState();
      console.log(usuarios, pacotes, seats, valor_usuarios);
      const response = await API.PROCESSO.set.salva_usuarios(
        cadastro.uuid,
        usuarios,
        pacotes,
        seats,
        valor_usuarios
      );
      return args;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const criaCheckoutLink = createAsyncThunk(
  CADASTRO.CRIA_STRIPE_LINK,
  async (_, { getState }) => {
    const { cadastro } = getState();
    try {
      let pacote = await API.PLANOS.get.plano(cadastro.plano_selecionado);
      let cod_plano = pacote.codigo_plano;
      let cod_pacote = pacote.codigo_pacote;
      // if (isDev) {
      //   cod_plano = pacote.codigo_plano_dev;
      //   cod_pacote = pacote.codigo_pacote_dev;
      // }
      const empresa = cadastro.empresa;
      let payload = {
        codigo_plano: cod_plano,
        email: empresa.email,
        nome: empresa.nome_responsavel,
        cnpj: empresa.cnpj,
        telefone: empresa.telefone,
        razao_social: empresa.razao_social,
        pacotes: cadastro.pacotes,
        codigo_pacote: cod_pacote,
        usuarios: cadastro.seats,
        uuid: cadastro.uuid,
      };

      let response = await API.STRIPE.set.checkout(payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const cadastroSlice = createSlice({
  name: "cadastro",
  initialState: {
    status: STATUS.LOADING,
    mensagem: "",
    uuid: "",
    plano_selecionado: "unique2024",
    stripe_customer_id: "",
    stripe_subscription_id: "",
    stripe_checkout_session_id: "",
    passo_atual: PASSOS.DADOS_RESPONSAVEL.id,
    processando: false,
    aceito: false,
    stripe_url: "",
    orcamento: false,

    // Dados da empresa
    empresa: {
      nome_fantasia: "",
      razao_social: "",
      cnpj: "",
      telefone: "",
      email: "",
      nome_responsavel: "",
      validated: false,
    },

    // Dados do administrador
    administradores: [
      {
        nome: "",
        email: "",
        telefone: "",
      },
    ],

    // Dados de usuarios
    usuarios: undefined,
    pacotes: undefined,
    seats: undefined,
    valor_usuarios: undefined,

    // Dados do pagamento
    pagamento: {
      status: STATUS.IDLE,
      mensagem: "",
    },
  },
  reducers: {
    setOrcamento: (state, action) => {
      state.orcamento = action.payload;
    },
    setStatus: (state, action) => {
      // define o status da tela de cadastro
      state.status = action.payload.status;
      state.mensagem = action.payload.mensagem || "";
    },
    irParaPasso: (state, action) => {
      // define o passo atual da tela de cadastro
      state.passo_atual = action.payload.id;
      state.processando = false;
    },
    setStatusPagamento: (state, action) => {
      // define o status do pagamento
      state.pagamento.status = action.payload.status;
      state.pagamento.mensagem = action.payload.mensagem || "";
    },
    setField: (state, action) => {
      // define o valor de um campo do cadastro
      state[action.payload.field] = action.payload.value;
    },
    setDadosEmpresa: (state, action) => {
      // define os dados da empresa
      state.empresa[action.payload.field] = action.payload.value;
    },
    setDadosAdministradores: (state, action) => {
      // define os dados dos administradores
      state.administradores = action.payload;
    },
    setDadosUsuarios: (state, action) => {
      // define os dados dos usuários
      state.usuarios = action.payload;
    },
    proximoPasso: (state, action) => {
      // avança para o próximo passo do cadastro
      state.passo_atual += 1;
    },
    passoAnterior: (state, action) => {
      // volta para o passo anterior do cadastro
      state.passo_atual -= 1;
    },
    selecionaPlano: (state, action) => {
      // define o plano selecionado
      if (action.payload === "starter" && state.usuarios > 100) {
        state.usuarios = 100;
        state.pacotes = 2;
        state.seats = 100;
      }
      state.plano_selecionado = action.payload;
    },
    toggleAceito: (state, action) => {
      // define o plano selecionado
      state.aceito = !state.aceito;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(carregaProcesso.pending, (state, action) => {
        state.status = STATUS.LOADING;
      })
      .addCase(carregaProcesso.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        let processo = action.payload;
        state.uuid = processo.uuid;
        state.plano_selecionado = processo.plano;
        state.stripe_checkout_session_id = processo.stripe_checkout_session_id;
        state.empresa.cnpj = processo.cnpj;
        state.empresa.nome_fantasia = processo.nome_fantasia;
        state.empresa.razao_social = processo.razao_social;
        state.empresa.telefone = processo.telefone;
        state.empresa.validated = true;
        state.empresa.email = processo.email;
        state.empresa.nome_responsavel = processo.responsavel;
        state.usuarios = processo.usuarios;
        state.pacotes = processo.pacotes;
        state.seats = processo.seats;
        state.valor_usuarios = processo.valor_usuarios;
        state.aceito = true;
      })
      .addCase(carregaProcesso.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        console.log(action);
        state.mensagem = action.error.message;
      })
      .addCase(criaProcesso.pending, (state, action) => {
        state.processando = true;
      })
      .addCase(criaProcesso.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        state.uuid = action.payload;
        if (state.plano_selecionado !== "") {
          // alterar se voltarmos a ter planos
          state.passo_atual += 1;
        } else {
          state.passo_atual += 1;
        }
        state.processando = false;
      })
      .addCase(criaProcesso.rejected, (state, action) => {
        console.log("erro", action.error.message);
        state.status = STATUS.FAILED;
        state.mensagem = action.error.message;
        state.processando = false;
      })
      .addCase(salvaPlano.pending, (state, action) => {
        state.processando = true;
      })
      .addCase(salvaPlano.fulfilled, (state, action) => {
        state.processando = false;
        state.passo_atual += 1;
      })
      .addCase(salvaPlano.rejected, (state, action) => {
        state.processando = false;
        state.mensagem = action.error.message;
      })
      .addCase(setNumeroUsuarios.pending, (state, action) => {
        state.processando = true;
      })
      .addCase(setNumeroUsuarios.fulfilled, (state, action) => {
        state.usuarios = action.payload.usuarios;
        state.pacotes = action.payload.pacotes;
        state.seats = action.payload.seats;
        state.valor_usuarios = action.payload.valor_usuarios;
        state.processando = false;
        state.passo_atual += 1;
      })
      .addCase(criaCheckoutLink.pending, (state, action) => {
        state.processando = true;
      })
      .addCase(criaCheckoutLink.fulfilled, (state, action) => {
        state.stripe_checkout_session_id = action.payload;
        state.stripe_url = action.payload.url;
      })
      .addCase(criaCheckoutLink.rejected, (state, action) => {
        state.processando = false;
        state.mensagem = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setStatus,
  setStatusPagamento,
  setField,
  setDadosEmpresa,
  setDadosAdministradores,
  setDadosUsuarios,
  proximoPasso,
  passoAnterior,
  selecionaPlano,
  toggleAceito,
  irParaPasso,
  setOrcamento,
} = cadastroSlice.actions;

export default cadastroSlice.reducer;

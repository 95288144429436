import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Loading from "../../Components/Loading";
import Sidebar from "../../Components/Sidebar";
import Stepper from "../../Components/Stepper";
import Wizard from "../../Components/Wizard";
import {
  carregaProcesso,
  selecionaPlano,
  setOrcamento,
  setStatus,
} from "../../redux/cadastroSlice";
import { PASSOS, PLANOS, STATUS } from "../../utils/constants";
import DadosEmpresa from "../DadosEmpresa";
import DadosResponsavel from "../DadosResponsavel";
import Funcionarios from "../Funcionarios";
import Pagamento from "../Pagamento";
import SelecaoPlanos from "../SelecaoPlanos";
import Header from "./Header";

import * as S from "./styles";

const STEPS = [
  <DadosResponsavel />,
  <Funcionarios />,
  <Pagamento />,
];

function Home({ orcamento = false }) {
  // pega query params
  const plano = new URLSearchParams(window.location.search).get("plano");

  const dispatch = useDispatch();
  const { passo_atual, status } = useSelector((state) => state.cadastro);
  // params
  const { uuid } = useParams();
  const [oito, setOito] = useState(false);
  // se tiver params, deve pegar os dados atuais da empresa

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (
      passo_atual === PASSOS.DADOS_PAGAMENTO.id ||
      passo_atual === PASSOS.DADOS_RESPONSAVEL.id
    ) {
      setOito(false);
    } else {
      setOito(true);
    }
  }, [passo_atual]);

  async function init() {
    if (plano) {
      let plano = PLANOS.find((p) => p.id === plano);
      
      console.log("plano", plano)
      dispatch(selecionaPlano(plano));
    }
    if (orcamento) {
      dispatch(setOrcamento(orcamento));
    }
    if (uuid) {
      // pega os dados atuais da empresa
      // pega o processo atual da empresa
      dispatch(carregaProcesso(uuid));
    } else {
      dispatch(setStatus({ status: STATUS.IDLE }));
    }
  }

  if (status === STATUS.LOADING) {
    return (
      <S.Container>
        <Loading />
      </S.Container>
    );
  }

  if (status === STATUS.FAILED) {
    return <S.Container>Ocorreu um erro</S.Container>;
  }

  return (
    <S.Container>
      <Container fluid={false}>
        <Row>
          <Col md={12}>
            <Header />
          </Col>
          <Col md={12}>
            <Stepper />
          </Col>
          <Col md={oito ? 8 : 12}>
            <Wizard step={passo_atual} steps={STEPS} />
          </Col>
          {oito && (
            <Col md={4}>
              <Sidebar />
            </Col>
          )}
        </Row>
      </Container>
    </S.Container>
  );
}

export default Home;

import React, { useEffect } from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import ReactSlider from "react-slider";
import { abbreviateNumber } from "../../utils/functions";

const Container = styled.div`
  width: 100%;
  padding: 35px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
`;

const StyledThumb = styled.div`
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
  background-color: ${colors.primary};
  color: #fff;
  border-radius: 50%;
  border: 1px solid ${colors.primary};
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -12px;
`;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) => (props.index === 1 ? "#ccc" : colors.primary)};
  border-radius: 999px;
`;

function Slider({ min = 10, max = 30000, step, usuarios, onChange, value }) {
  const steps = [
    min,
    step * 2,
    step * 3,
    step * 4,
    step * 5,
    step * 10,
    step * 15,
    step * 20,
    step * 25,
    step * 50,
    step * 100,
    step * 200,
    step * 300,
    step * 400,
    step * 500,
    step * 1000,
    step * 2000,
    step * 3000,
  ].filter((step) => (max ? step <= max : true));
  const Track = (props, state) => (
    <StyledTrack {...props} index={state.index} />
  );

  const Thumb = (props, state) => {
    let number = abbreviateNumber(usuarios);
    console.log(number)
    if (state.valueNow === steps.length - 1 && number && number.toString().indexOf("k") > -1) {
      number = `${abbreviateNumber(steps[state.valueNow - 1])}+`;
    }
    return <StyledThumb {...props}>{number}</StyledThumb>;
  };

  useEffect(() => {
    if(!usuarios) return
    // gets the first entry that is higher than the value
    let step = steps.findIndex((step) => step >= usuarios);
    if(step === -1) step = steps.length -1
    if(usuarios > max) usuarios = max
    onChange(step, usuarios);
  }, [usuarios]);


  function handleChange(e) {
    let step = e;
    let usuarios = steps[step];
    console.log("usuarios", usuarios);
    onChange(step, usuarios);
  }
  return (
    <Container>
      <StyledSlider
        value={value}
        renderTrack={Track}
        renderThumb={Thumb}
        min={0}
        max={steps.length - 1}
        step={1}
        onChange={handleChange}
        usuarios={usuarios}
      />
    </Container>
  );
}

export default Slider;

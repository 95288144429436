import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../Api";
import Loading from "../../Components/Loading";
import Tooltip from "../../Components/Tooltip";
import {
  passoAnterior,
  proximoPasso,
  salvaPlano,
  selecionaPlano,
} from "../../redux/cadastroSlice";

import * as S from "../main-styles";
import * as L from "./styles";

function SelecaoPlanos() {
  const dispatch = useDispatch();
  const { plano_selecionado, processando } = useSelector(
    (state) => state.cadastro
  );
  const [state, setState] = useState({
    planos: [],
    loading: true,
    loadingDetalhes: true,
    detalhes: [],
    mostrarDetalhes: false,
  });
  const { planos, loading, detalhes, loadingDetalhes, mostrarDetalhes } = state;

  async function pegaPlanos() {
    const planos = await API.PLANOS.get.planos();
    console.log("planos", planos);
    setState((s) => ({
      ...s,
      planos: planos,
      loading: false,
    }));
  }
  async function pegaDetalhes() {
    let detalhes = await API.PLANOS.get.features();
    console.log("detalhes", detalhes);
    detalhes = detalhes.sort((a, b) => a.id - b.id);
    setState((s) => ({ ...s, detalhes: detalhes, loadingDetalhes: false }));
  }

  function toggleMostraDetalhes() {
    setState((s) => ({ ...s, mostrarDetalhes: !s.mostrarDetalhes }));
  }

  function next() {
    console.log("proximo passo");
    dispatch(salvaPlano());
  }

  function prev(){
    dispatch(passoAnterior())
  }

  useEffect(() => {
    pegaPlanos();
  }, []);

  useEffect(() => {
    if (loading === false) {
      pegaDetalhes();
    }
  }, [loading]);
  return (
    <S.Container>
      <S.Title>Selecione o plano</S.Title>
      <L.Container>
        {loading ? (
          <div />
        ) : (
          Object.values(planos).map((plano) => {
            console.log("plano", plano);
            return <Plano plano={plano} />;
          })
        )}
      </L.Container>
      <S.Container
        style={{
          marginTop: 20,
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <S.Text
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={toggleMostraDetalhes}
        >
          Mostrar detalhes dos planos
        </S.Text>
        <S.FH>
          <S.ButtonSecondary
            onClick={prev}
            disabled={processando}
          >
            Anterior
          </S.ButtonSecondary>

          <S.Button
            onClick={next}
            disabled={plano_selecionado === "" || processando}
            style={{marginLeft: 10}}
          >
            {processando ? "Processando..." : "Próximo"}
          </S.Button>
        </S.FH>
      </S.Container>

      {mostrarDetalhes && (
        <S.Form>
          {loadingDetalhes ? <Loading /> : <Detalhes detalhes={detalhes} />}
        </S.Form>
      )}
    </S.Container>
  );
}

function Plano({ plano }) {
  const dispatch = useDispatch();
  const { plano_selecionado } = useSelector((state) => state.cadastro);
  const { nome, id } = plano;
  const selecionado = id === plano_selecionado;

  function seleciona() {
    dispatch(selecionaPlano(id));
  }
  return (
    <L.Plano selected={selecionado} onClick={seleciona}>
      <S.FHSB>
        <L.NomePlano>{nome}</L.NomePlano>
        {selecionado && <FontAwesomeIcon icon={faCheck} />}
      </S.FHSB>
    </L.Plano>
  );
}

function Detalhes({ detalhes }) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope={"thead"} style={{ width: "33%" }}>
            Starter
          </th>
          <th scope={"thead"} style={{ width: "33%" }}>
            Professional
          </th>
          <th scope={"thead"} style={{ width: "33%" }}>
            Enterprise
          </th>
        </tr>
      </thead>
      <tbody>
        {detalhes.map((detalhe, index) => {
          const { id, nome, starter, professional, enterprise, all } = detalhe;
          return <Line data={detalhe} key={index} index={index} />;
        })}
      </tbody>
    </table>
  );
}

function Line({ data, index }) {
  const { id, nome, starter, professional, enterprise, all, help } = data;
  switch (true) {
    case all !== undefined:
      return (
        <tr>
          <L.Feature available={true}>
            <S.FH>
              <FontAwesomeIcon
                icon={faCheck}
                style={{ marginRight: 5 }}
                color={"green"}
              />
              {all.nome}
              {help && <Help help={help} id={`${index}_${id}_1`} />}
            </S.FH>
          </L.Feature>
          <L.Feature available={true}>
            <S.FH>
              <FontAwesomeIcon
                icon={faCheck}
                style={{ marginRight: 5 }}
                color={"green"}
              />
              {all.nome}
              {help && <Help help={help} id={`${index}_${id}_2`} />}
            </S.FH>
          </L.Feature>
          <L.Feature available={true}>
            <S.FH>
              <FontAwesomeIcon
                icon={faCheck}
                style={{ marginRight: 5 }}
                color={"green"}
              />
              {all.nome}
              {help && <Help help={help} id={`${index}_${id}_3`} />}
            </S.FH>
          </L.Feature>
        </tr>
      );
    default:
      return (
        <tr>
          <L.Feature available={starter.available}>
            <S.FH>
              <FontAwesomeIcon
                icon={starter.available ? faCheck : faTimes}
                color={starter.available ? "green" : "red"}
                style={{ marginRight: 5 }}
              />
              {starter.nome}
              {help && <Help help={help} id={`${index}_${id}_1`} />}
            </S.FH>
          </L.Feature>
          <L.Feature available={professional.available}>
            <S.FH>
              <FontAwesomeIcon
                icon={professional.available ? faCheck : faTimes}
                color={professional.available ? "green" : "red"}
                style={{ marginRight: 5 }}
              />
              {professional.nome}
              {help && <Help help={help} id={`${index}_${id}_2`} />}
            </S.FH>
          </L.Feature>
          <L.Feature available={enterprise.available}>
            <S.FH>
              <FontAwesomeIcon
                icon={enterprise.available ? faCheck : faTimes}
                color={enterprise.available ? "green" : "red"}
                style={{ marginRight: 5 }}
              />
              {enterprise.nome}
              {help && <Help help={help} id={`${index}_${id}_3`} />}
            </S.FH>
          </L.Feature>
        </tr>
      );
      break;
  }
}

function Help({ help }) {
  return (
    <Tooltip text={help}>
      <FontAwesomeIcon
        icon={faQuestionCircle}
        style={{ marginLeft: 5 }}
        color={"#4495ff"}
      />
    </Tooltip>
  );
}

export default SelecaoPlanos;

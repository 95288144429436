import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`;

export const Plano = styled.div`
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${(props) => (props.selected ? "#fff" : "transparent")};
`;

export const NomePlano = styled.div``;

export const ValorPlano = styled.div``;

export const DescricaoPlano = styled.div``;

export const Feature = styled.td`
  vertical-align: center;
  opacity: ${(props) => (props.available ? 1 : 0.3)};
  font-size: 13px;
  text-decoration: ${(props) => (props.available ? "none" : "line-through")};
`;


export const Help = styled.div`

`;
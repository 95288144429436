import styled from "styled-components";
import colors from "../utils/colors";

export const Container = styled.div``;

export const MainContainer = styled.div`
    min-height: 100vh;
    background-color: #fbedf8;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-bottom: 10px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${colors.text};
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 14px;
  color: ${colors.text};
`;

export const Title = styled.h1`
  font-size: 24px;
  color: ${colors.primary};
`;

export const Text = styled.div`
  font-size: 14px;
  color: ${colors.text};
`;

export const Description = styled(Text)`
  font-size: 12px;
  color: #999999;
`;

export const Button = styled.div`
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => (props.disabled ? "#bababa" : colors.primary)};
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.4s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  :hover {
    background-color: #fff;
    color: ${colors.primary};
  }
`;

export const ButtonSecondary = styled(Button)`
  background-color: #fff;
  color: ${colors.primary};
  :hover {
    background-color: ${colors.primary};
    color: #fff;
  }
`;

export const FVSB = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
`;

export const CheckBox = styled.input`
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 14px;
  color: ${colors.text};
  margin-right: 10px;
`;

export const FH = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const FHSB = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HR = styled.hr`
  width: 100%;
  border: 1px dotted #333;
`;

export const Subtitle = styled.h2`
  font-size: 18px;
  color: ${colors.primary};
`;

export const DescriptionContainer = styled.div`
  font-size: 12px;
  color: #999999;
  line-height: 1;
  margin-top: 10px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #ccc;
`;

export const StripeLogo = styled.img`
  height: 40px;
  width: auto;
  object-fit: contain;
  margin-top: 10px;
`;


export const Clickable = styled.span`
  color: ${colors.primary};
  cursor: pointer;
  text-decoration: underline;
`
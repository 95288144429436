import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 20px;
  margin-bottom: 20px;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  font-size: 10px;
  color: ${(props) => (props.active ? colors.primary : props.done ? `${colors.primary}73` : "#ccc")};
`;

export const StepTitle = styled.div`
  margin-top: 5px;
`;

export const StepNumber = styled.div`
  font-size: 10px;
  background-color: ${(props) => (props.active ? colors.primary : props.done ? `${colors.primary}73` : "#ccc")};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

import React from "react";
import { useSelector } from "react-redux";
import { Form, Text, Title } from "../../telas/main-styles";

import PoweredByStripe from "../../assets/Powered-by-Stripe.png";

import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function Sidebar() {
  const { cadastro } = useSelector((state) => state);
  const { uuid, plano_selecionado, usuarios, pacotes, seats } = cadastro;
  let protocolo = uuid.split("-")[0];

  //copia para o clipboard
  function copyToClipBoard(text) {
    navigator.clipboard.writeText(text);
    toast.success("Copiado!", { position: "bottom-center" });
  }

  function copyProcesso() {
    copyToClipBoard(`https://contrate.seidecor.com.br/processo/${uuid}`);
  }

  return (
    <S.Container>
      <Title>Resumo</Title>
      <Form>
        <Text>Processo</Text>
        <Text onClick={copyProcesso} style={{ cursor: "pointer" }}>
          {protocolo || "-"} <FontAwesomeIcon icon={faClipboard} />
        </Text>
        {/* <Text>Plano:</Text>
        <Text style={{ textTransform: "uppercase" }}>
          {plano_selecionado || "Único"}
        </Text>
        <Text>Pacotes de Usuários:</Text>
        <Text>{pacotes || "-"}</Text> */}
        <Text>Usuários</Text>
        <Text>
          {usuarios || "-"}
        </Text>
      </Form>
      <a href="https://www.stripe.com/br" target="_blank">
        <S.StripeImg src={PoweredByStripe} />
      </a>
    </S.Container>
  );
}

export default Sidebar;

import React from "react";

import * as S from "./styles";

function DadosAdministrador() {
  return (
    <S.Container>
    </S.Container>
  );
}

export default DadosAdministrador;

import styled from 'styled-components';

export const Container = styled.div`
  
`;


export const StripeImg = styled.img`
    height: 20px;
    width: auto;
    object-fit: contain;
    margin-top: 20px;
`;
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  criaProcesso,
  proximoPasso,
  selecionaPlano,
  setDadosEmpresa,
} from "../../redux/cadastroSlice";

import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import * as S from "../main-styles";
import API from "../../Api";
import { validateCNPJ } from "../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function DadosResponsavel() {
  const dispatch = useDispatch();
  const { empresa, processando, uuid, aceito, orcamento } = useSelector(
    (state) => state.cadastro
  );
  function handleChange(field, value) {
    dispatch(setDadosEmpresa({ field, value }));
    dispatch(selecionaPlano("unique2024"))
  }

  async function getCompanyData() {
    if (!empresa.cnpj || !validateCNPJ(empresa.cnpj)) {
      return false;
    }
    const data = await API.INFO.cnpjInfo(empresa.cnpj);
    if (data) {
      handleChange("nome_fantasia", data.fantasyName);
      handleChange("razao_social", data.name);
      handleChange("validated", true);
    } else {
      toast.error("CNPJ inválido");
      handleChange("nome_fantasia", "");
      handleChange("razao_social", "");
      handleChange("validated", false);
    }
  }

  function handleCNPJ(cnpj) {
    // accept only numbers
    if (isNaN(cnpj[cnpj.length - 1])) {
      return false;
    }

    if (cnpj.length === 18) {
      getCompanyData(cnpj);
    }

    handleChange("cnpj", cnpj);
  }

  function validate() {
    if (!empresa.validated) {
      toast.error("Você precisa informar um CNPJ válido");
      return false;
    }
    if (!empresa.telefone) {
      toast.error("Você precisa informar o telefone do responsável");
      return false;
    }
    if (!empresa.email) {
      toast.error("Você precisa informar o email do responsável");
      return false;
    }
    if (!empresa.nome_responsavel) {
      toast.error("Você precisa informar o nome do responsável");
      return false;
    }

    dispatch(criaProcesso());
    //dispatch(proximoPasso());
  }

  return (
    <S.Container>
      <Row>
        <Col md={3} />
        <Col md={6}>
          <S.Form>
            <S.Title>Dados da Empresa</S.Title>
            <S.FormGroup>
              <S.Label>CNPJ</S.Label>
              <S.FH style={{width: '100%'}}>
                <S.Input
                  type="text"
                  value={empresa.cnpj}
                  onChange={(e) => handleCNPJ(e.target.value)}
                  onBlur={getCompanyData}
                />
                {empresa.validated && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green", marginLeft: 10 }}
                  />
                )}
              </S.FH>
            </S.FormGroup>
            <S.HR />
            <S.Title>Dados do Responsável</S.Title>
            <S.FormGroup>
              <S.Label>Nome</S.Label>
              <S.Input
                type="text"
                value={empresa.nome_responsavel}
                onChange={(e) =>
                  handleChange("nome_responsavel", e.target.value)
                }
              />
            </S.FormGroup>
            <S.FormGroup>
              <S.Label>Telefone</S.Label>
              <S.Input
                type="text"
                value={empresa.telefone}
                onChange={(e) => handleChange("telefone", e.target.value)}
              />
            </S.FormGroup>
            <S.FormGroup>
              <S.Label>E-mail</S.Label>
              <S.Input
                type="text"
                value={empresa.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </S.FormGroup>
          </S.Form>
        </Col>
        <Col md={3} />
        <Col md={3} />
        <Col md={6} style={{ padding: 20 }}>
          <S.Button
            style={{ float: "right" }}
            disabled={processando}
            onClick={validate}
          >
            {processando
              ? "Processando..."
              : uuid
              ? "Continuar cadastro"
              : "Iniciar cadastro"}
          </S.Button>
        </Col>
        <Col md={3} />
      </Row>
    </S.Container>
  );
}

export default DadosResponsavel;

import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import LogoSeidecor from "../../../assets/logopblack.png";
import * as S from "../../main-styles";
function Header() {
  const cadastro = useSelector((state) => state.cadastro);
  console.log(cadastro);
  const { orcamento } = cadastro;
  return (
    <Container>
      <Row>
        <Col md={9}>
          <img
            src={LogoSeidecor}
            alt="Logo Seidecor"
            style={{ marginBottom: 30, marginTop: 30 }}
          />
        </Col>
        <Col md={3}>
          {orcamento && (
            <div style={{ marginBottom: 30, marginTop: 30 }}>
              <S.Subtitle>Orçamento</S.Subtitle>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Header;

import React from 'react'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default ({ children, text,id, placement="bottom" }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <div ref={ref} {...triggerHandler}>{children}</div>
      )}
    </OverlayTrigger>
  );
}


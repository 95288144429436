import { configureStore } from '@reduxjs/toolkit'


import cadastroReducer from './redux/cadastroSlice'


export default configureStore({
  reducer: {
    cadastro: cadastroReducer
  },
})
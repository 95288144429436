import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  criaProcesso,
  setDadosEmpresa,
  toggleAceito,
} from "../../redux/cadastroSlice";

import * as L from "./styles";
import * as S from "../main-styles";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import { validateCNPJ } from "../../utils/functions";

function DadosEmpresa() {
  const dispatch = useDispatch();
  const { empresa, processando, uuid, aceito, orcamento } = useSelector(
    (state) => state.cadastro
  );
  function handleChange(field, value) {
    dispatch(setDadosEmpresa({ field, value }));
  }

  function accept() {
    dispatch(toggleAceito());
  }

  function validate() {
    if (!empresa.nome_fantasia) {
      toast.error("Você precisa informar o nome fantasia da empresa");
      return false;
    }
    if (!empresa.razao_social) {
      toast.error("Você precisa informar a razão social da empresa");
      return false;
    }
    if (!empresa.cnpj || !validateCNPJ(empresa.cnpj)) {
      toast.error("Você precisa informar o CNPJ da empresa");
      return false;
    }
    dispatch(criaProcesso());
  }

  return (
    <S.Container>
      <Row>
        <Col md={3} />
        <Col md={6}>
          <S.Form>
            <S.Title>Dados da empresa</S.Title>
            <S.FormGroup>
              <S.Label>Nome fantasia</S.Label>
              <S.Input
                type="text"
                value={empresa.nome_fantasia}
                onChange={(e) => handleChange("nome_fantasia", e.target.value)}
              />
            </S.FormGroup>
            <S.FormGroup>
              <S.Label>Razão social</S.Label>
              <S.Input
                type="text"
                value={empresa.razao_social}
                onChange={(e) => handleChange("razao_social", e.target.value)}
              />
            </S.FormGroup>
            <S.FormGroup>
              <S.Label>CNPJ</S.Label>
              <S.Input
                type="text"
                value={empresa.cnpj}
                onChange={(e) => handleChange("cnpj", e.target.value)}
              />
            </S.FormGroup>
          </S.Form>
        </Col>
        <Col md={3} />
        <Col md={3} />
        <Col md={6} style={{ padding: 20 }}>
          <S.Button
            style={{ float: "right" }}
            disabled={processando}
            onClick={validate}
          >
            {processando ? "Processando..." : "Próximo passo"}
          </S.Button>
        </Col>
        <Col md={3} />
      </Row>
    </S.Container>
  );
}

export default DadosEmpresa;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../Api";
import Loading from "../../Components/Loading";
import * as S from "../main-styles";
import * as L from "./styles";
import Slider from "../../Components/Slider";
import { Col, Container, Row } from "reactstrap";
import {
  passoAnterior,
  proximoPasso,
  setNumeroUsuarios,
} from "../../redux/cadastroSlice";

function Funcionarios() {
  const [precoUsuario, setPrecoUsuario] = useState(0);
  const dispatch = useDispatch();
  const cadastro = useSelector((state) => state.cadastro);
  const { plano_selecionado } = cadastro;
  const [state, setState] = useState({
    plano: {},
    loading: true,
    usuarios: 0,
    step: 0,
  });

  const { usuarios, loading, plano, step } = state;
  const {
    maximo_usuarios,
    minimo_usuarios,
    pacote_usuarios,
    preco_pacote,
    preco_usuario,
  } = plano;

  useEffect(() => {
    pegaPacote();
  }, []);

  useEffect(() => {
    updatePrecoUsuario();
  }, [usuarios]);

  function updatePrecoUsuario() {
    console.log("Calcula para ", usuarios);
    /* 
    QUANTIDADE
PREÇO POR UNIDADE
VALOR FIXO
Se a quantidade total for de 1 a 20
R$ 15,90
-
Se a quantidade total for de 21 a 50
R$ 13,50
—
Se a quantidade total for de 51 a 150
R$ 11,50
—
Se a quantidade total for de 151 a 400
R$ 9,80
—
Se a quantidade total for de 401 a 650
R$ 8,30
—
Se a quantidade total for de 651 a 900
R$ 7,00
—
Se a quantidade total for de 901 a 1,200
R$ 5,90
—
Se a quantidade total for de 1,201 a 1,600
R$ 5,00
—
Se a quantidade total for de 1,601 a 2,000
R$ 4,50
—
Se a quantidade total for de 2,001 a 2,500
R$ 4,00
—
Se a quantidade total for de 2,501 a 3,500
R$ 3,50
—
Se a quantidade total for de 3,501 a 4,000
R$ 3,20
—
Se a quantidade total for de 4,001 a 6,000
R$ 3,00
—
Se a quantidade total for 6,001 ou mais
R$ 2,80

    */

    let preco = 0;
    switch (true) {
      case usuarios <= 20:
        preco = 15.9;
        break;
      case usuarios <= 50:
        preco = 13.5;
        break;
      case usuarios <= 150:
        preco = 11.5;
        break;
      case usuarios <= 400:
        preco = 9.8;
        break;
      case usuarios <= 650:
        preco = 8.3;
        break;
      case usuarios <= 900:
        preco = 7.0;
        break;
      case usuarios <= 1200:
        preco = 5.9;
        break;
      case usuarios <= 1600:
        preco = 5.0;
        break;
      case usuarios <= 2000:
        preco = 4.5;
        break;
      case usuarios <= 2500:
        preco = 4.0;
        break;
      case usuarios <= 3500:
        preco = 3.5;
        break;
      case usuarios <= 4000:
        preco = 3.2;
        break;
      case usuarios <= 6000:
        preco = 3.0;
        break;
      case usuarios > 6000:
        preco = 2.8;
        break;

      default:
        preco = 15.9;
        break;
    }
    setPrecoUsuario(preco);
  }

  async function pegaPacote() {
    let planos = await API.PLANOS.get.planos();
    console.log("planos", planos);
    let plano = Object.values(planos)[0];
    console.log("plano", plano);
    setState({
      ...state,
      plano,
      loading: false,
      usuarios: cadastro.usuarios || plano.minimo_usuarios,
    });
  }

  function updateUsuarios(step, usuarios) {
    setState({
      ...state,
      usuarios: usuarios,
      step: step,
    });
  }

  function proximo() {
    let pacotes_necessarios = Math.ceil(usuarios / pacote_usuarios);
    let total_seats = pacotes_necessarios * pacote_usuarios;
    let p_pacote = precoUsuario * pacote_usuarios;
    let valor_usuarios = pacotes_necessarios * p_pacote;
    dispatch(
      setNumeroUsuarios({
        usuarios,
        pacotes: pacotes_necessarios,
        seats: total_seats,
        valor_usuarios,
      })
    );
  }

  let pacotes_necessarios = Math.ceil(usuarios / pacote_usuarios);
  let total_seats = pacotes_necessarios * pacote_usuarios;
  let p_pacote = precoUsuario * pacote_usuarios;
  let valor_usuarios = pacotes_necessarios * p_pacote;
  return (
    <S.Container>
      <S.Title>Usuários</S.Title>
      {loading ? (
        <S.Form>
          {" "}
          <Loading />
        </S.Form>
      ) : (
        <S.Form>
          <Container style={{ marginTop: 20 }}>
            <Row>
              <Col md={6}>
                <S.Box>
                  <S.Subtitle>Informações</S.Subtitle>
                  <span>
                    <strong>Mínimo:</strong> <strong>{minimo_usuarios}</strong>{" "}
                  </span>
                  <span>
                    <b>Máximo:</b>
                    {maximo_usuarios ? (
                      <>
                        <strong> {maximo_usuarios}</strong> usuários
                      </>
                    ) : (
                      <>
                        <strong> Sem limite</strong> de usuários contratados
                      </>
                    )}
                  </span>
                  <span>
                    <strong>Preço por usuário: </strong>
                    <strong>R${precoUsuario.toLocaleString('pt-br', {minimumFractionDigits: 2})}</strong>/mês
                  
                  </span>
                  <span><strong>Desconto por volume: </strong>Ativado</span>
                </S.Box>
              </Col>

              <Col md={6}>
                <S.Box>
                  <S.Subtitle>SubTotal</S.Subtitle>
                  <span>
                    <strong>
                      Usuários: {usuarios}
                    </strong>
                  </span>
                  <S.HR />
                  <span>
                    <strong>Total:</strong> R$
                    {(plano.mensalidade + valor_usuarios).toLocaleString('pt-br', {minimumFractionDigits: 2})}
                  </span>
                </S.Box>
              </Col>

              <Col md={12}>
                <S.HR />
              </Col>
              <Col md={3}>
                <S.Subtitle>Quantos usuários você precisa?</S.Subtitle>
                <S.Input
                  type="number"
                  min={minimo_usuarios}
                  max={maximo_usuarios}
                  value={usuarios}
                  onChange={(e) =>
                    updateUsuarios(pacotes_necessarios - 1, e.target.value)
                  }
                />
              </Col>
              <Col md={9}>
                <Slider
                  min={minimo_usuarios}
                  max={maximo_usuarios}
                  step={pacote_usuarios}
                  value={step}
                  usuarios={usuarios}
                  onChange={updateUsuarios}
                />
              </Col>

              <Col md={12}>
                <S.HR />
                <S.FHSB>
                  <S.ButtonSecondary onClick={() => dispatch(passoAnterior())}>
                    Anterior
                  </S.ButtonSecondary>
                  <S.Button onClick={proximo}>Próximo</S.Button>
                </S.FHSB>
              </Col>
            </Row>
          </Container>
        </S.Form>
      )}
    </S.Container>
  );
}

export default Funcionarios;

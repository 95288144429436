import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Modal, ModalBody, Row } from "reactstrap";
import API from "../../Api";
import Loading from "../../Components/Loading";
import LogoStripe from "../../assets/stripe.png";
import { formataCNPJ, formataTelefone } from "../../utils/functions";

import * as S from "../main-styles";
import { criaCheckoutLink, irParaPasso } from "../../redux/cadastroSlice";
import { PASSOS } from "../../utils/constants";
import { Overlay } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const CHECKOUT_LINK = "https://checkout.stripe.com/checkout.js";

function Pagamento({ aceite }) {
  const dispatch = useDispatch();
  const { cadastro } = useSelector((state) => state);
  const { orcamento } = cadastro;
  const [state, setState] = useState({
    pacote: {},
    carregando: true,
    consultoria: false,
  });
  const { pacote, carregando, consultoria } = state;
  const {
    empresa,
    plano_selecionado,
    usuarios,
    pacotes,
    seats,
    valor_usuarios,
    uuid,
    stripe_url,
    processando,
  } = cadastro;
  const { email } = empresa;

  useEffect(() => {
    pegaPacote();
  }, []);

  async function pegaPacote() {
    let pacote = await API.PLANOS.get.plano(plano_selecionado);
    setState((s) => ({ ...s, pacote: pacote, carregando: false }));
  }

  async function generateCheckout() {
    dispatch(criaCheckoutLink());
  }

  function passo(id) {
    dispatch(irParaPasso(id));
  }

  function copyToClipBoard(text) {
    navigator.clipboard.writeText(text);
  }
  function copyOrcamento() {
    copyToClipBoard(`https://contrate.seidecor.com.br/aceite/${uuid}`);
    toast.success("Copiado!", { position: "bottom-center" });
  }

  useEffect(() => {
    if (stripe_url) {
      window.location.href = stripe_url;
    }
  }, [stripe_url]);

  return (
    <S.Container>
      {carregando ? (
        <Loading />
      ) : (
        <Container>
          <Row>
            <Col md={12}>
              {aceite ? (
                <S.Title>Informações de Contratação</S.Title>
              ) : (
                <S.Title>Dados para pagamento</S.Title>
              )}
            </Col>
            <Col md={6}>
              <S.Form>
                <>
                  <S.Subtitle>Informações</S.Subtitle>
                  <S.Text>Conforme solicitado segue link da cotação</S.Text>
                  <S.HR />
                  <S.Subtitle>
                    Dados da Empresa{" "}
                    {!aceite && (
                      <>
                        [
                        <S.Clickable
                          onClick={() => passo(PASSOS.DADOS_EMPRESA)}
                        >
                          Editar
                        </S.Clickable>
                        ]
                      </>
                    )}
                  </S.Subtitle>
                  <S.Text>{empresa.nome_fantasia}</S.Text>
                  <S.Text>{empresa.razao_social}</S.Text>
                  <S.Text>{formataCNPJ(empresa.cnpj)}</S.Text>
                  <S.HR />
                  <S.Subtitle>
                    Responsável
                    {!aceite && (
                      <>
                        [
                        <S.Clickable
                          onClick={() => passo(PASSOS.DADOS_RESPONSAVEL)}
                        >
                          Editar
                        </S.Clickable>
                        ]
                      </>
                    )}
                  </S.Subtitle>
                  <S.Text>{empresa.nome_responsavel}</S.Text>
                  <S.Text>{empresa.email}</S.Text>
                  <S.Text>{formataTelefone(empresa.telefone)}</S.Text>
                </>
              </S.Form>
            </Col>
            <Col md={6}>
              <S.Form>
                <S.FVSB>
                  <div>
                    <S.Subtitle>Pagamento</S.Subtitle>
                    <S.StripeLogo src={LogoStripe} />
                    <S.Text>
                      A Sei de Cor utiliza o{" "}
                      <a href="https://www.stripe.com" target={"_blank"}>
                        Stripe
                      </a>{" "}
                      para facilitar o seu pagamento.
                    </S.Text>
                    <S.HR />
                    <S.Subtitle>Carrinho</S.Subtitle>
                    <table className="table table-small">
                      <tbody>
                        <tr>
                          <td>
                            {usuarios} usuários
                            {!aceite && (
                              <>
                                [
                                <S.Clickable
                                  onClick={() => passo(PASSOS.USUARIOS)}
                                >
                                  Editar
                                </S.Clickable>
                                ]
                              </>
                            )}
                          </td>
                          <td>R$ {valor_usuarios.toLocaleString('pt-br', {minimumFractionDigits: 2})}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <strong>
                              R${" "}
                              {parseFloat(valor_usuarios).toLocaleString('pt-br', {minimumFractionDigits: 2})}
                            </strong>{" "}
                            / mês
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {orcamento ? (
                    <S.Text
                      style={{ cursor: "pointer" }}
                      onClick={copyOrcamento}
                    >
                      Link do orçamento:
                      <br />
                      https://contrate.seidecor.com.br/aceite/{uuid}
                      <FontAwesomeIcon
                        icon={faCopy}
                        style={{ marginLeft: 5 }}
                      />
                    </S.Text>
                  ) : (
                    <div>
                      <S.Button onClick={generateCheckout}>
                        Ir para pagamento
                      </S.Button>
                      <S.DescriptionContainer>
                        <S.Description>
                          Você será redirecionado para um ambiente seguro,
                          controlado pelo Stripe
                        </S.Description>
                      </S.DescriptionContainer>
                    </div>
                  )}
                </S.FVSB>
              </S.Form>
            </Col>
          </Row>
        </Container>
      )}
      {processando && (
        <Modal isOpen={true}>
          <ModalBody
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <S.Subtitle>Você será redirecionado em breve...</S.Subtitle>
          </ModalBody>
        </Modal>
      )}
    </S.Container>
  );
}

export default Pagamento;

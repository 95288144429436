import React from "react";
import { Route, Routes } from "react-router-dom";

// ROTAS
import { useSelector } from "react-redux";
import DadosAdministrador from "../telas/DadosAdministrador";
import DadosEmpresa from "../telas/DadosEmpresa";
import Funcionarios from "../telas/Funcionarios";
import HandOver from "../telas/HandOver";
import Home from "../telas/Home";
import Pagamento from "../telas/Pagamento";
import SelecaoPlanos from "../telas/SelecaoPlanos";
import Sucesso from "../telas/Sucesso";
import Erro from "../telas/Erro";
import Calculadora from "../telas/Calculadora";
import Aceite from "../telas/Aceite";

export default function Root() {
  const { pagamento } = useSelector((state) => state);
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/processo/:uuid" element={<Home />} />
      <Route path="/success/:CHECKOUT_SESSION_ID" element={<Sucesso />} />
      <Route path="/cancel/:CHECKOUT_SESSION_ID" element={<Erro />} />

      <Route exact path="/calculadora" element={<Calculadora />} />
      <Route exact path="/orcamento" element={<Home orcamento={true} />} />
      <Route exact path="/orcamento/:uuid" element={<Home orcamento={true} />} />
      <Route exact path="/aceite/:uuid" element={<Aceite />} />

      <Route path="/configure/:uuid" element={<Home />}>
        <Route path="administradores" element={<DadosAdministrador />} />
        <Route path="bem-vindo" element={<HandOver />} />
      </Route>
    </Routes>
  );
}

import axios from "axios";
import { API_URL } from "../utils/constants";

const PLANOS = {
  unique2024: {
    id: "unique2024",
    nome: "Único2024",
    mensalidade: 0,
    minimo_usuarios: 1,
    pacote_usuarios: 1,
    maximo_usuarios: undefined,
    preco_usuario: 0,
    preco_pacote: 0,
    horas_conteudista: 0,
    preco_hora_conteudista: 150,
    suporte: "FAQ",
    codigo_plano: undefined,
    codigo_pacote: "prod_PDsy8GKxabU11p"
  },
  starter: {
    id: "starter",
    nome: "Starter",
    mensalidade: 250,
    minimo_usuarios: 50,
    pacote_usuarios: 50,
    maximo_usuarios: 100,
    preco_pacote: 300,
    preco_usuario: 6,
    horas_conteudista: 1,
    preco_hora_conteudista: 150,
    suporte: "FAQ",
    codigo_plano: "prod_N7UrQ0yKBWYLpd",
    codigo_pacote: "prod_MhJz7h4LXXzd62",
  },
  professional: {
    id: "professional",
    nome: "Professional",
    mensalidade: 400,
    minimo_usuarios: 10,
    pacote_usuarios: 10,
    maximo_usuarios: undefined,
    preco_usuario: 8,
    preco_pacote: 80,
    horas_conteudista: 8,
    preco_hora_conteudista: 120,
    suporte: "FAQ + Chat + Ticket",
    codigo_plano: "prod_MhJzpMPHuFg5bv",
    codigo_pacote: "prod_MhJzAQD15PItUK",
  },
  enterprise: {
    id: "enterprise",
    nome: "Enterprise",
    mensalidade: 600,
    minimo_usuarios: 10,
    pacote_usuarios: 10,
    maximo_usuarios: undefined,
    preco_usuario: 10,
    preco_pacote: 100,
    horas_conteudista: 8,
    preco_hora_conteudista: 90,
    suporte: "FAQ + Chat + Ticket",
    codigo_plano: "prod_MhJz5iJxnPc1P2",
    codigo_pacote: "prod_MhJyaiCcr1rDtU",
  },
};

const FEATURES = [
  {
    id: "suporte",
    ordem: 0,
    starter: {
      nome: "Suporte: FAQ",
      available: true,
    },
    professional: {
      nome: "Suporte: FAQ + Chat + Ticket",
      available: true,
    },
    enterprise: {
      nome: "Suporte: FAQ + Chat + Ticket",
      available: true,
    },
  },
  {
    id: "conteudistas",
    ordem: 1,
    help: "Preço por hora de conteudistas",
    starter: {
      nome: `Conteudistas: R$ ${PLANOS.starter.preco_hora_conteudista}/h`,
      available: true,
    },
    professional: {
      nome: `Conteudistas: R$ ${PLANOS.professional.preco_hora_conteudista}/h`,
      available: true,
    },
    enterprise: {
      nome: `Conteudistas: R$ ${PLANOS.enterprise.preco_hora_conteudista}/h`,
      available: true,
    },
  },
  {
    id: "horas_conteudistas",
    ordem: 2,
    help: "Horas de conteudistas/mês incluídas",
    starter: {
      nome: `${PLANOS.starter.horas_conteudista} horas de conteudistas/mês`,
      available: true,
    },
    professional: {
      nome: `${PLANOS.professional.horas_conteudista} horas de conteudistas/mês`,
      available: true,
    },
    enterprise: {
      nome: `${PLANOS.enterprise.horas_conteudista} horas de conteudistas/mês`,
      available: true,
    },
  },
  {
    id: "apoio_conteudo",
    ordem: 3,
    help:
      "Dicas e orientações para criação de conteúdo de qualidade na plataforma",
    all: {
      nome: "Apoio profissional para embarque de seus conteúdos na plataforma",
      available: true,
    },
  },
  {
    id: "cursos",
    ordem: 4,
    help: "Quantidade de cursos que podem ser criados",
    starter: {
      nome: "Até 30 cursos",
      available: true,
    },
    professional: {
      nome: "Até 100 cursos",
      available: true,
    },
    enterprise: {
      nome: "Cursos ilimitados",
      available: true,
    },
  },
  {
    id: "segmentos",
    ordem: 5,
    help:
      "Crie segmentos de usuários para personalizar o conteúdo da plataforma",
    starter: {
      nome: "Até 10 segmentos",
      available: true,
    },
    professional: {
      nome: "Segmentos ilimitados",
      available: true,
    },
    enterprise: {
      nome: "Segmentos ilimitados",
      available: true,
    },
  },
  {
    id: "push_notifications",
    ordem: 6,
    help: "Envie notificações para seus usuários",
    all: {
      nome: "Notificações por Push",
      available: true,
    },
  },
  {
    id: "trilha_de_conhecimento",
    ordem: 7,
    help: "Guie seus usuários por trilhas de conhecimento",
    starter: {
      nome: "1 trilha de conhecimento",
      available: true,
    },
    professional: {
      nome: "5 trilhas de conhecimento",
      available: true,
    },
    enterprise: {
      nome: "Trilhas de conhecimento ilimitadas",
      available: true,
    },
  },
  {
    id: "produtos",
    ordem: 8,
    help: "Quantidade de produtos que podem ser criados na plataforma",
    starter: {
      nome: "Até 50 produtos",
      available: true,
    },
    professional: {
      nome: "Produtos ilimitados",
      available: true,
    },
    enterprise: {
      nome: "Produtos ilimitados",
      available: true,
    },
  },
  {
    id: "feed",
    ordem: 9,
    help: "Crie um feed de notícias e novidades para seus usuários",
    all: {
      nome: "Feed",
      available: true,
    },
  },
  {
    id: "stories",
    ordem: 10,
    help: "Envie stories para seus usuários",
    all: {
      nome: "Stories",
      available: true,
    },
  },
  {
    id: "niveis",
    ordem: 11,
    help:
      "Faça com que seus usuários passem de nível conforme façam novos cursos",
    all: {
      nome: "Gamificação: Níveis",
      available: true,
    },
  },
  {
    id: "ranking",
    ordem: 12,
    help: "Crie rankings de usuários",
    starter: {
      nome: "Gamificação: Ranking",
      available: false,
    },
    professional: {
      nome: "Gamificação: Ranking",
      available: true,
    },
    enterprise: {
      nome: "Gamificação: Ranking",
      available: true,
    },
  },
  {
    id: "badges",
    ordem: 13,
    help:
      "Crie badges para seus usuários poderem ganhar e mostrar para os colegas",
    starter: {
      nome: "Gamificação: Badges",
      available: false,
    },
    professional: {
      nome: "Gamificação: 5 Badges",
      available: true,
    },
    enterprise: {
      nome: "Gamificação: Badges ilimitadas",
      available: true,
    },
  },
  {
    id: "lojinha",
    ordem: 14,
    help:
      "Crie uma lojinha para seus usuários poderem comprar produtos virtuais para mostrarem em seus perfis",
    starter: {
      nome: "Gamificação: Lojinha",
      available: false,
    },
    professional: {
      nome: "Gamificação: Lojinha",
      available: true,
    },
    enterprise: {
      nome: "Gamificação: Lojinha",
      available: true,
    },
  },
  {
    id: "equipes",
    ordem: 15,
    help: "Organize e acompanhe o engajamento de seus usuários em equipes",
    starter: {
      nome: "Equipes",
      available: false,
    },
    professional: {
      nome: "Equipes",
      available: true,
    },
    enterprise: {
      nome: "Equipes",
      available: true,
    },
  },
  {
    id: "coach_virtual",
    ordem: 16,
    help: "Um bot que guiará seus usuários por todo o processo de aprendizado",
    starter: {
      nome: "Coach Virtual",
      available: false,
    },
    professional: {
      nome: "Coach Virtual",
      available: true,
    },
    enterprise: {
      nome: "Coach Virtual",
      available: true,
    },
  },
  {
    id: "chat",
    ordem: 17,
    help: "Chat para seus usuários poderem conversar",
    starter: {
      nome: "Chat no App",
      available: false,
    },
    professional: {
      nome: "Chat no App",
      available: true,
    },
    enterprise: {
      nome: "Chat no App",
      available: true,
    },
  },
  {
    id: "analise_motivacional",
    ordem: 18,
    help:
      "Analise o perfil motivacional de seus usuários e tenha alertas de talentos e riscos, além de poder criar estratégias para melhorar o fator motivacional de seus colaboradores",
    starter: {
      nome: "Análise Motivacional",
      available: false,
    },
    professional: {
      nome: "Análise Motivacional",
      available: false,
    },
    enterprise: {
      nome: "Análise Motivacional",
      available: true,
    },
  },
  {
    id: "formularios",
    help: "Crie formulários para seus usuários responderem",
    ordem: 19,
    starter: {
      nome: "Formulários",
      available: false,
    },
    professional: {
      nome: "Formulários",
      available: false,
    },
    enterprise: {
      nome: "Formulários",
      available: true,
    },
  },
  {
    id: "live_streaming",
    ordem: 20,
    help: "Transmita ao vivo para seus usuários",
    starter: {
      nome: "Live Streaming",
      available: false,
    },
    professional: {
      nome: "Live Streaming",
      available: false,
    },
    enterprise: {
      nome: "Live Streaming (30 minutos / mês)",
      available: true,
    },
  },
  {
    id: "suporte_prioritario",
    ordem: 21,
    starter: {
      nome: "Suporte Prioritário",
      available: false,
    },
    professional: {
      nome: "Suporte Prioritário",
      available: false,
    },
    enterprise: {
      nome: "Suporte Prioritário",
      available: true,
    },
  },
];

const API = {
  PLANOS: {
    set: {},
    get: {
      planos: () => PLANOS,
      features: () => FEATURES,
      plano: (id) => PLANOS[id],
    },
  },
  STRIPE: {
    get: {},
    set: {
      checkout: async (payload) => {
        try {
          let r = await axios.post(
            `${API_URL}/create-checkout-session`,
            payload
          );
          console.log("r", r);
          return r.data;
        } catch (error) {
          console.error("e", error);
        }
      },
    },
  },
  PROCESSO: {
    get: {
      byUUID: async (uuid) => {
        console.log("vai buscar processo", uuid);
        let r = await axios.get(`${API_URL}/processo/${uuid}`);
        return r.data;
      },
    },
    set: {
      dados_empresa: async (
        nome_fantasia,
        razao_social,
        cnpj,
        email,
        telefone,
        responsavel,
        uuid
      ) => {
        // return axios.post(`${API_URL}/processo/dados_empresa`, {nome, cnpj, email, telefone})
        let r = await axios.post(`${API_URL}/create-sdc-session`, {
          nome_fantasia,
          razao_social,
          cnpj,
          email,
          telefone,
          responsavel,
          uuid,
        });
        return r.data.uuid;
        // deve retornar o UUID do processo
      },
      salva_plano: async (uuid, plano) => {
        return axios.post(`${API_URL}/plano`, { uuid, plano });
      },
      salva_usuarios: async (
        uuid,
        usuarios,
        pacotes,
        seats,
        valor_usuarios
      ) => {
        return axios.post(`${API_URL}/usuarios`, {
          uuid,
          usuarios,
          pacotes,
          seats,
          valor_usuarios,
        });
      },
      dados_administradores: async (uuid, administradores) => {
        // return axios.post(`${API_URL}/processo/dados_administradores`, {administradores})
      },
    },
  },
  INFO: {
    cnpjInfo: async (cnpj)=>{
      try {
        let token = "e6d77531-7112-4362-b36c-d0d894893cbc"
        //trata o cnpj
        cnpj = cnpj.replace(/[^\d]+/g, '');
        let r = await axios.post(`${API_URL}/cp-info`, {cnpj, token});
        return r.data;
      } catch (error) {
        console.error(error);
      }
    }
  }
};

export default API;

export const PLANOS = {
  STARTER: "starter",
  PROFESSIONAL: "professional",
  ENTERPRISE: "enterprise",
};

export const isDev = process.env.NODE_ENV === "development";

console.log('Em desenvolvimento? ', isDev)
if(!isDev){
  console.log = () => {}
  console.warn = () => {}
  console.error = () => {}
}

export const API_URL = isDev
  ? "http://localhost:3000/checkout"
  : "https://gql.seidecor.com.br/checkout";

export const STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  REFRESHING: "refreshing",
  LOADING_MORE: "loading_more",
  PENDING: "pending",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};

export const PASSOS = {
  DADOS_RESPONSAVEL: { id: 0, nome: "Informações" },
  USUARIOS: { id: 2, nome: "Usuários" },
  DADOS_PAGAMENTO: { id: 3, nome: "Dados de pagamento" },
  CONFIRMACAO: { id: 4, nome: "Confirmação" },
};

import React from "react";
import { useSelector } from "react-redux";
import { PASSOS } from "../../utils/constants";

import * as S from "./styles";

function Stepper() {
  const { passo_atual } = useSelector((state) => state.cadastro);
  return (
    <S.Container>
      {Object.values(PASSOS).map((item, index) => {
        return (
          <S.Step
            key={index}
            active={passo_atual === index}
            done={passo_atual > index}
          >
            <S.StepNumber
              active={passo_atual === index}
              done={passo_atual > index}
            >
              {index + 1}
            </S.StepNumber>
            <S.StepTitle
              active={passo_atual === index}
              done={passo_atual > index}
            >
              {item.nome}
            </S.StepTitle>
          </S.Step>
        );
      })}
    </S.Container>
  );
}

export default Stepper;
